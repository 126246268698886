const HighlightPHIWords = ({ text, phiWords }) => {
    const escapeRegExp = (string) => {
        return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    };

    const phiWordsPattern = phiWords.length > 0 ? phiWords.map(escapeRegExp).join('|') : '';
    const regex = new RegExp(`\\b(${phiWordsPattern})\\b`, 'gi');

    const highlightedText = text.replace(regex, (match) => `<mark>${match}</mark>`).replace(/\n\n/g, '<br><br>');

    return <div className="transcription-text" dangerouslySetInnerHTML={{ __html: highlightedText }} />;
};

export default HighlightPHIWords;
