import React, { useState } from 'react';
import './index.css';

const Settings = ({ onSaveSettings, initialSettings }) => {
    const [settings, setSettings] = useState(initialSettings);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setSettings((prevSettings) => {
            const newSettings = { ...prevSettings, [name]: value };
            onSaveSettings(newSettings);
            return newSettings;
        });
    };

    const handleToggleChange = (name) => (event) => {
        setSettings((prevSettings) => {
            const currentSetting = prevSettings[name] === 'true';
            const newSetting = !currentSetting;
            const updatedSettings = {
                ...prevSettings,
                [name]: newSetting ? 'true' : 'false'
            };
            onSaveSettings(updatedSettings);
            return updatedSettings;
        });
    };

    return (
        <div>
            <div>
                <h5>Transcribe Medical</h5>
                <label className="relative inline-flex items-center mb-4 cursor-pointer">
                    <input type="checkbox" className="sr-only peer" checked={settings.useTranscribeMedical === 'true'} onChange={handleToggleChange('useTranscribeMedical')} />
                    <div className="w-11 h-6 bg-gray-200 rounded-full  dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-shc-blue"></div>
                    <span className="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">{settings.useTranscribeMedical === 'true' ? 'Using Transcribe Medical' : 'Disabled (will use the regular transcribe)'}</span>
                </label>
            </div>
            {settings.useTranscribeMedical === 'true' && (
                <div>
                    <div className="mt-4">
                        <h5>Specialty</h5>

                        <div>
                            <select
                                id="specialty"
                                name="specialty"
                                value={settings.specialty}
                                onChange={handleChange}
                                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                                <option value="PRIMARYCARE">Primary Care</option>
                                <option value="CARDIOLOGY">Cardiology</option>
                                <option value="NEUROLOGY">Neurology</option>
                                <option value="ONCOLOGY">Oncology</option>
                                <option value="RADIOLOGY">Radiology</option>
                                <option value="UROLOGY">Urology</option>
                            </select>
                        </div>
                    </div>

                    <div className="mt-6">
                        <h5>Type</h5>

                        <select
                            id="type"
                            name="type"
                            value={settings.type}
                            onChange={handleChange}
                            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                            <option value="DICTATION">Dictation</option>
                            <option value="CONVERSATION">Conversation</option>
                        </select>
                    </div>

                    {settings.type === 'CONVERSATION' && (
                        <div className="mt-6">
                            <h5>Identify Speakers</h5>
                            <label className="relative inline-flex items-center mb-4 cursor-pointer">
                                <input type="checkbox" className="sr-only peer" checked={settings.identifySpeakers === 'true'} onChange={handleToggleChange('identifySpeakers')} />
                                <div className="w-11 h-6 bg-gray-200 rounded-full dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-shc-blue"></div>
                                <span className="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">{settings.identifySpeakers === 'true' ? 'Enabled' : 'Disabled'}</span>
                            </label>
                        </div>
                    )}

                    {settings.type === 'CONVERSATION' && settings.identifySpeakers === 'true' && (
                        <div className="mt-6">
                            <h5>Show Speaker Labels</h5>
                            <label className="relative inline-flex items-center mb-4 cursor-pointer">
                                <input type="checkbox" className="sr-only peer" checked={settings.showSpeakerLabels === 'true'} onChange={handleToggleChange('showSpeakerLabels')} />
                                <div className="w-11 h-6 bg-gray-200 rounded-full dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-shc-blue"></div>
                                <span className="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">{settings.showSpeakerLabels === 'true' ? 'Enabled' : 'Disabled'}</span>
                            </label>
                        </div>
                    )}

                    <div className="mt-6">
                        <h5>PHI Detection</h5>
                        <label className="relative inline-flex items-center mb-4 cursor-pointer">
                            <input type="checkbox" className="sr-only peer" checked={settings.enablePHIDetection === 'true'} onChange={handleToggleChange('enablePHIDetection')} />
                            <div className="w-11 h-6 bg-gray-200 rounded-full dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-shc-blue"></div>
                            <span className="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">{settings.enablePHIDetection === 'true' ? 'Enabled' : 'Disabled'}</span>
                        </label>
                    </div>
                </div>
            )}

            <div className="mt-5 border-y py-5">
                <h5>JSON Output</h5>
                <label className="relative inline-flex items-center mb-4 cursor-pointer">
                    <input type="checkbox" className="sr-only peer" checked={settings.showJSONOutput === 'true'} onChange={handleToggleChange('showJSONOutput')} />
                    <div className="w-11 h-6 bg-gray-200 rounded-full dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-shc-blue"></div>
                    <span className="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">{settings.showJSONOutput === 'true' ? 'Showing' : 'Hidden'}</span>
                </label>
            </div>
        </div>
    );
};

export default Settings;
