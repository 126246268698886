import React from 'react';

const TranscriptionSettingsDisplay = ({ settings }) => {
    return (
        <div>
            <div>
                <span>API:</span> {settings.useTranscribeMedical === 'true' ? 'Amazon Transcribe Medical' : 'Amazon Transcribe'}
            </div>
            {settings.useTranscribeMedical === 'true' ? (
                <div>
                    <div>
                        <span>PHI Detection:</span> <span className={settings.enablePHIDetection === 'true' ? 'text-shc-blue font-bold' : 'text-shc-red'}>{settings.enablePHIDetection === 'true' ? 'Enabled' : 'Disabled'}</span>
                    </div>
                    <div>
                        <span>Specialty:</span> {settings.specialty}
                    </div>
                    <div>
                        <span>Type:</span> {settings.type}
                    </div>
                    <div>
                        <span>Identify Speakers:</span> <span className={settings.identifySpeakers === 'true' ? 'text-shc-blue font-bold' : 'text-shc-red'}>{settings.identifySpeakers === 'true' ? 'Enabled' : 'Disabled'}</span>
                    </div>
                    {settings.identifySpeakers === 'true' && (
                        <div>
                            <span>Show Speaker Labels:</span> <span className={settings.showSpeakerLabels === 'true' ? 'text-shc-blue font-bold' : 'text-shc-red'}>{settings.showSpeakerLabels === 'true' ? 'Enabled' : 'Disabled'}</span>
                        </div>
                    )}
                </div>
            ) : (
                <div>Choose Transcribe Medical for more options.</div>
            )}
        </div>
    );
};

export default TranscriptionSettingsDisplay;
