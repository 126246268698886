import React from 'react';

export const formatSoapText = (soapText) => {
    const regex = /(SUBJECTIVE:|OBJECTIVE:|ASSESSMENT:|PLAN:|BILLING CODES:|H&P:|PROBLEM LIST:|ICD-10 Codes:)\s*/i;
    const sections = soapText.split(regex);

    if (sections.length <= 1) {
        return <p>{soapText}</p>;
    }

    const formattedSections = [];

    for (let i = 1; i < sections.length; i += 2) {
        formattedSections.push(
            <React.Fragment key={i}>
                <h5>{sections[i].toUpperCase()}</h5>
                <p>{sections[i + 1]}</p>
                <br />
            </React.Fragment>
        );
    }

    return formattedSections;
};
