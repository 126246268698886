import React from 'react';
import { useState, useRef, useEffect } from 'react';
import AWS from 'aws-sdk';
import { TranscribeClient, StartMedicalTranscriptionJobCommand, GetMedicalTranscriptionJobCommand } from '@aws-sdk/client-transcribe';
import { TranscribeStreamingClient, StartStreamTranscriptionCommand, StartMedicalStreamTranscriptionCommand } from '@aws-sdk/client-transcribe-streaming';
import { FaMicrophone, FaStop, FaSpinner } from 'react-icons/fa';
import './App.css';
import MicrophoneStream from 'microphone-stream';
import { Buffer } from 'buffer';
import { awsConfig } from './awsConfig';
import HighlightPHIWords from './HighlightPHIWords';
import Settings from './Settings';
import History from './History/History';
import { formatSoapText } from './Common/utils';
import TranscriptionSettingsDisplay from './TranscriptionSettingsDisplay';
import { VscChromeClose } from 'react-icons/vsc';

AWS.config.update({
    region: awsConfig.awsRegion,
    accessKeyId: awsConfig.awsAccessKeyId,
    secretAccessKey: awsConfig.awsSecretAccessKey
});

const App = () => {
    const [recording, setRecording] = useState(false);
    const [liveTranscriptionText, setLiveTranscriptionText] = useState('');
    const [liveTranscriptionJson, setLiveTranscriptionJson] = useState({});
    const [batchTranscriptionText, setBatchTranscriptionText] = useState('');
    const [transcribing, setTranscribing] = useState(false);
    const [activeTab, setActiveTab] = useState('Live Transcript');
    const [recordedAudioBlob, setRecordedAudioBlob] = useState(null);
    const [isFetchingMedicalNote, setIsFetchingMedicalNote] = useState(false);
    const [isFetchingProgressNote, setIsFetchingProgressNote] = useState(false);
    const [phiWords, setPhiWords] = useState([]);
    const [medicalNote, setMedicalNote] = useState(null);
    const [progressNote, setProgressNote] = useState(null);
    const [settingsVisible, setSettingsVisible] = useState(false);
    const [historyVisible, setHistoryVisible] = useState(false);
    const [sessions, setSessions] = useState([]);
    const [recordingStartTime, setRecordingStartTime] = useState(null);
    const [recordingEndTime, setRecordingEndTime] = useState(null);
    const [currentSessionId, setCurrentSessionId] = useState(null);

    const [transcriptionSettings, setTranscriptionSettings] = useState({
        useTranscribeMedical: 'true',
        specialty: 'PRIMARYCARE',
        type: 'CONVERSATION',
        enablePHIDetection: 'true',
        identifySpeakers: 'true',
        showSpeakerLabels: 'false',
        showJSONOutput: 'false'
    });

    const audioChunks = useRef([]);
    const mediaRecorder = useRef(null);
    const audioURL = useRef(null);
    const firstRun = useRef(true);
    const microphoneStream = useRef(null);
    const llmEndPoint = 'https://pocmyhealth.stanfordhealthcare.org/orion/private/api/v2/llm/azure/gpt/completion';

    let transcribeStreamingClient = undefined;
    const SAMPLE_RATE = 44100;

    useEffect(() => {
        if (!recording && liveTranscriptionText && liveTranscriptionText.length > 0) {
            fetchMedicalNote(liveTranscriptionText);
            fetchProgressNote(liveTranscriptionText);
        }
    }, [liveTranscriptionText, recording]);

    const saveSettings = (newSettings) => {
        setTranscriptionSettings(newSettings);
    };

    const toggleRecording = () => {
        if (recording) {
            setRecordingEndTime(new Date());
            setRecording(false);
            stopLiveTranscription();
        } else {
            setRecordingStartTime(new Date());
            setRecording(true);
            startLiveTranscription();
        }
    };

    const saveSessionData = () => {
        const sessionId = new Date().getTime();
        setCurrentSessionId(sessionId);

        const newSession = {
            id: sessionId,
            liveTranscriptionText,
            medicalNote: null,
            progressNote: null,
            transcriptionSettings,
            startTime: recordingStartTime,
            endTime: recordingEndTime,
            phiWords: phiWords
        };
        setSessions((prevSessions) => [...prevSessions, newSession]);
    };

    const updateSessionWithMedicalNote = (sessionId, medicalNote) => {
        setSessions((prevSessions) => prevSessions.map((session) => (session.id === sessionId ? { ...session, medicalNote } : session)));
    };

    const updateSessionWithProgressNote = (sessionId, progressNote) => {
        setSessions((prevSessions) => prevSessions.map((session) => (session.id === sessionId ? { ...session, progressNote } : session)));
    };

    // live transcription
    const createTranscribeStreamingClient = () => {
        transcribeStreamingClient = new TranscribeStreamingClient({
            region: awsConfig.awsRegion,
            credentials: {
                accessKeyId: awsConfig.awsAccessKeyId,
                secretAccessKey: awsConfig.awsSecretAccessKey
            }
        });
    };

    const startLiveTranscription = async () => {
        setActiveTab('Live Transcript');
        createTranscribeStreamingClient();
        setLiveTranscriptionText('');
        setBatchTranscriptionText('');
        startRecording();
        let partialSegment = '';
        let currentText = '';
        const identifySpeakers = transcriptionSettings.identifySpeakers === 'true';

        await startStreaming((text, isPartial) => {
            partialSegment = text;

            if (!isPartial) {
                currentText = currentText + ' ' + text;
                partialSegment = '';
            }

            setLiveTranscriptionText(currentText + ' ' + partialSegment);
        }, identifySpeakers);
    };

    const stopLiveTranscription = () => {
        saveSessionData();
        setRecordingStartTime(null);
        setRecordingEndTime(null);

        if (transcribeStreamingClient) {
            transcribeStreamingClient.destroy();
            transcribeStreamingClient = undefined;
        }

        stopRecording();
    };

    const startStreaming = async (callback) => {
        let options = {
            LanguageCode: 'en-US',
            MediaEncoding: 'pcm',
            MediaSampleRateHertz: SAMPLE_RATE,
            AudioStream: getAudioStream()
        };

        if (transcriptionSettings.useTranscribeMedical === 'true') {
            options.Specialty = transcriptionSettings.specialty;
            options.Type = transcriptionSettings.type;

            if (transcriptionSettings.type === 'CONVERSATION' && transcriptionSettings.identifySpeakers === 'true') {
                options.ShowSpeakerLabel = true;
                options.MaxSpeakerLabels = 2;
            }

            if (transcriptionSettings.enablePHIDetection === 'true') {
                options.ContentIdentificationType = 'PHI';
            } else {
                setPhiWords([]);
            }
        } else {
            setPhiWords([]);
        }

        let command = new StartStreamTranscriptionCommand(options);

        if (transcriptionSettings.useTranscribeMedical === 'true') {
            command = new StartMedicalStreamTranscriptionCommand(options);
        }

        const data = await transcribeStreamingClient.send(command);
        let eventsArray = [];
        let phiWordsArray = [];
        let firstSpeaker = true;

        for await (const event of data.TranscriptResultStream) {
            const results = event.TranscriptEvent.Transcript.Results;
            if (results.length) {
                const alternatives = results[0].Alternatives;
                const items = alternatives.length > 0 ? alternatives[0].Items : [];
                const isPartial = results[0].IsPartial;
                let transcript = alternatives[0].Transcript;

                const speakerChanged = items.some((item) => item.Type === 'speaker-change');
                const speakerItem = items.find((item) => item.Speaker !== undefined);

                if (speakerChanged && speakerItem) {
                    if (!firstSpeaker) {
                        if (transcriptionSettings.showSpeakerLabels === 'true') {
                            transcript = `\n\nSpeaker ${parseInt(speakerItem.Speaker) + 1}: ${transcript}`;
                        } else {
                            transcript = `\n\n${transcript}`;
                        }
                    } else {
                        if (transcriptionSettings.showSpeakerLabels === 'true') {
                            transcript = `Speaker ${parseInt(speakerItem.Speaker) + 1}: ${transcript}`;
                        } else {
                            transcript = `${transcript}`;
                        }
                    }

                    firstSpeaker = false;
                } else {
                    transcript = transcript.trim();
                }

                callback(transcript, isPartial);
                console.log(`${transcript}, isPartial: ${isPartial}`);

                if (!isPartial && transcriptionSettings.useTranscribeMedical && transcriptionSettings.enablePHIDetection && alternatives[0].Entities && alternatives[0].Entities.length > 0) {
                    const phiWords = alternatives[0].Entities.filter((entity) => entity.Category.includes('PHI'));

                    phiWords.forEach((phiWord) => {
                        phiWordsArray.push(phiWord.Content);
                    });

                    setPhiWords(phiWordsArray);
                }

                eventsArray.push(event);
                setLiveTranscriptionJson(eventsArray);
            }
        }
    };

    const encodePCMChunk = (chunk) => {
        const input = MicrophoneStream.toRaw(chunk);
        let offset = 0;
        const buffer = new ArrayBuffer(input.length * 2);
        const view = new DataView(buffer);
        for (let i = 0; i < input.length; i++, offset += 2) {
            let s = Math.max(-1, Math.min(1, input[i]));
            view.setInt16(offset, s < 0 ? s * 0x8000 : s * 0x7fff, true);
        }
        return Buffer.from(buffer);
    };

    const getAudioStream = async function* () {
        for await (const chunk of microphoneStream.current) {
            if (chunk.length <= SAMPLE_RATE) {
                yield {
                    AudioEvent: {
                        AudioChunk: encodePCMChunk(chunk)
                    }
                };
            }
        }
    };

    const startRecording = () => {
        microphoneStream.current = new MicrophoneStream();
        audioChunks.current = [];
        audioURL.current = null;

        if (firstRun.current) {
            firstRun.current = false;
        } else {
            setTranscribing(false);
        }

        navigator.mediaDevices
            .getUserMedia({ audio: true })
            .then((stream) => {
                microphoneStream.current.setStream(stream);
                mediaRecorder.current = new MediaRecorder(stream);
                mediaRecorder.current.ondataavailable = (event) => {
                    if (event.data.size > 0) {
                        audioChunks.current.push(event.data);
                    }
                };

                mediaRecorder.current.onstop = () => {
                    const audioBlob = new Blob(audioChunks.current, {
                        type: 'audio/wav'
                    });
                    audioURL.current = URL.createObjectURL(audioBlob);
                    setRecordedAudioBlob(audioBlob);
                    //uploadAudioAndStartBatchTranscription(audioBlob);
                };

                mediaRecorder.current.start();
            })
            .catch((error) => {
                console.error('Error accessing microphone:', error);
            });
    };

    const stopRecording = () => {
        if (mediaRecorder.current && mediaRecorder.current.state === 'recording') {
            mediaRecorder.current.stop();
        }

        if (microphoneStream.current) {
            microphoneStream.current.stop();
            microphoneStream.current.destroy();
            microphoneStream.current = null;
        }
    };

    async function fetchMedicalNote(transcriptionText) {
        try {
            setIsFetchingMedicalNote(true);
            const payload = {
                payload: 'Here is the transcript of an encounter between a physician and a patient. Can you write the medical note? Please use SOAP format, and include any appropriate billing codes: ' + transcriptionText
            };

            const response = await fetch(llmEndPoint, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Cache-Control': 'no-cache'
                },
                body: JSON.stringify(payload)
            });

            const data = await response.json();

            if (data.responses && data.responses.length >= 1) {
                setMedicalNote(data.responses[0]);
                console.log(data.responses[0]);
                updateSessionWithMedicalNote(currentSessionId, data.responses[0]);
            } else {
                console.error('Unexpected response from LLM API');
            }
        } catch (error) {
            console.error('Error fetching medical note:', error);
        } finally {
            setIsFetchingMedicalNote(false);
        }
    }

    //todo: refactor this and fetchMedicalNote
    async function fetchProgressNote(transcriptionText) {
        try {
            setIsFetchingProgressNote(true);
            const payload = {
                payload: 'Here is the transcript of the encounter between a Doctor and a Patient. Can you write a medical note? H&P, problem list, ICD-10 codes. Please do not diagnose or come up with a plan: ' + transcriptionText
            };

            const response = await fetch(llmEndPoint, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Cache-Control': 'no-cache'
                },
                body: JSON.stringify(payload)
            });

            const data = await response.json();

            if (data.responses && data.responses.length >= 1) {
                setProgressNote(data.responses[0]);
                console.log(data.responses[0]);
                updateSessionWithProgressNote(currentSessionId, data.responses[0]);
            } else {
                console.error('Unexpected response from LLM API');
            }
        } catch (error) {
            console.error('Error fetching progress note:', error);
        } finally {
            setIsFetchingProgressNote(false);
        }
    }

    const handleTabClick = (tabLabel) => {
        setActiveTab(tabLabel);
    };

    return (
        <div className="app-container">
            <div className="flex items-center">
                <img src="logo-shc.png" alt="SHC Logo" className="mr-6 w-40" />
                <h1 className="border-l-2 border-gray-300 pl-6 font-semibold text-gray-600">Transcribe Prototype</h1>
            </div>

            <div className="inner-container max-w-4xl relative shadow-lg">
                <div className="cursor-pointer absolute top-5 right-5 text-gray-400 hover:text-gray-600" onClick={() => setSettingsVisible(true)}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M9.594 3.94c.09-.542.56-.94 1.11-.94h2.593c.55 0 1.02.398 1.11.94l.213 1.281c.063.374.313.686.645.87.074.04.147.083.22.127.324.196.72.257 1.075.124l1.217-.456a1.125 1.125 0 011.37.49l1.296 2.247a1.125 1.125 0 01-.26 1.431l-1.003.827c-.293.24-.438.613-.431.992a6.759 6.759 0 010 .255c-.007.378.138.75.43.99l1.005.828c.424.35.534.954.26 1.43l-1.298 2.247a1.125 1.125 0 01-1.369.491l-1.217-.456c-.355-.133-.75-.072-1.076.124a6.57 6.57 0 01-.22.128c-.331.183-.581.495-.644.869l-.213 1.28c-.09.543-.56.941-1.11.941h-2.594c-.55 0-1.02-.398-1.11-.94l-.213-1.281c-.062-.374-.312-.686-.644-.87a6.52 6.52 0 01-.22-.127c-.325-.196-.72-.257-1.076-.124l-1.217.456a1.125 1.125 0 01-1.369-.49l-1.297-2.247a1.125 1.125 0 01.26-1.431l1.004-.827c.292-.24.437-.613.43-.992a6.932 6.932 0 010-.255c.007-.378-.138-.75-.43-.99l-1.004-.828a1.125 1.125 0 01-.26-1.43l1.297-2.247a1.125 1.125 0 011.37-.491l1.216.456c.356.133.751.072 1.076-.124.072-.044.146-.087.22-.128.332-.183.582-.495.644-.869l.214-1.281z"
                        />
                        <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                    </svg>
                </div>

                <div className="cursor-pointer absolute top-5 left-5 text-gray-400 hover:text-gray-600" onClick={() => setHistoryVisible(true)}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25H12" />
                    </svg>
                </div>

                <div className="top-part">
                    <button className={`record-button ${recording ? 'recording recording-glow' : ''}`} onClick={toggleRecording}>
                        {recording ? <FaStop /> : <FaMicrophone />}
                    </button>

                    <div className="recording-message leading-tight">{recording ? 'Recording...' : firstRun.current ? 'Hit the record button to record and transcribe your conversation.' : 'Record another conversation'}</div>
                </div>

                <div className="bottom-part">
                    {liveTranscriptionText && audioURL.current && (
                        <div className="tab-container">
                            <div className={`tab ${activeTab === 'Live Transcript' ? 'active' : ''}`} onClick={() => handleTabClick('Live Transcript')}>
                                Live Transcript
                            </div>
                            {/* <div className={`tab ${activeTab === 'Batch Transcript' ? 'active' : ''}`} onClick={() => handleTabClick('Batch Transcript')}>
                                Batch Transcript
                            </div> */}
                            <div className={`tab ${activeTab === 'Medical Note' ? 'active' : ''}`} onClick={() => handleTabClick('Medical Note')}>
                                SOAP Note
                            </div>
                            <div className={`tab ${activeTab === 'Progress Note' ? 'active' : ''}`} onClick={() => handleTabClick('Progress Note')}>
                                H&P Note
                            </div>
                        </div>
                    )}

                    <div className="content">
                        {activeTab === 'Live Transcript' && (
                            <div>
                                {liveTranscriptionText ? (
                                    <div>
                                        <div className="transcription-container">
                                            <h5>Transcript</h5>
                                            <div className="transcription-text">
                                                <HighlightPHIWords text={liveTranscriptionText} phiWords={phiWords} />
                                            </div>
                                        </div>

                                        {transcriptionSettings.showJSONOutput === 'true' && (
                                            <div className="transcription-container">
                                                <h5>Response JSON</h5>
                                                <pre className="transcription-text">{JSON.stringify(liveTranscriptionJson, null, 2)}</pre>
                                            </div>
                                        )}
                                    </div>
                                ) : !recording ? (
                                    <div className="text-sm text-gray-600">
                                        <TranscriptionSettingsDisplay settings={transcriptionSettings} />
                                        <div className="cursor-pointer underline font-bold mt-5 text-shc-blue hover:opacity-80" onClick={() => setSettingsVisible(true)}>
                                            Change settings
                                        </div>
                                    </div>
                                ) : (
                                    <div></div>
                                )}
                            </div>
                        )}

                        {activeTab === 'Medical Note' && (
                            <div>
                                {isFetchingMedicalNote ? (
                                    <div className="medical-note flex">
                                        <FaSpinner className="spinner-icon mr-2 mt-1" /> Fetching medical note...
                                    </div>
                                ) : (
                                    <div>
                                        <div className="transcription-container">
                                            {medicalNote ? formatSoapText(medicalNote.text) : liveTranscriptionText ? <div>SOAP note not available. Try regenerating.</div> : <div>Waiting for live transcript to complete...</div>}
                                            <div className="text-sm font-mono uppercase text-gray-400 mt-2">{medicalNote && `Generated by ${medicalNote.modelName}`}</div>
                                        </div>
                                        <button type="button" onClick={() => fetchMedicalNote(liveTranscriptionText)} className="text-sm font-medium border px-3 py-1 rounded-full hover:opacity-70 mt-3">
                                            Regenerate SOAP Note
                                        </button>
                                    </div>
                                )}
                            </div>
                        )}

                        {activeTab === 'Progress Note' && (
                            <div>
                                {isFetchingProgressNote ? (
                                    <div className="medical-note flex">
                                        <FaSpinner className="spinner-icon mr-2 mt-1" /> Fetching progress note...
                                    </div>
                                ) : (
                                    <div>
                                        <div className="transcription-container">
                                            {progressNote ? formatSoapText(progressNote.text) : liveTranscriptionText ? <div>H&P note not available. Try regenerating.</div> : <div>Waiting for live transcript to complete...</div>}
                                            <div className="text-sm font-mono uppercase text-gray-400 mt-2">{progressNote && `Generated by ${progressNote.modelName}`}</div>
                                        </div>
                                        <button type="button" onClick={() => fetchProgressNote(liveTranscriptionText)} className="text-sm font-medium border px-3 py-1 rounded-full hover:opacity-70 mt-3">
                                            Regenerate H&P Note
                                        </button>
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                </div>
            </div>

            {settingsVisible && (
                <div className="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

                    <div className="max-w-sm mx-auto fixed inset-0 z-10 w-screen overflow-y-auto">
                        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                            <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                                <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                                    <div className="settings-modal">
                                        <Settings onSaveSettings={saveSettings} initialSettings={transcriptionSettings} />
                                    </div>
                                </div>
                                <div className="px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                                    <button type="button" onClick={() => setSettingsVisible(false)} className="uppercase bg-shc-blue rounded-sm inline-flex w-full justify-center px-4 py-1 text-white shadow-sm hover:bg-opacity-75 sm:ml-3 sm:w-auto">
                                        Done
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {historyVisible && (
                <div className="relative z-50" aria-labelledby="modal-title" role="dialog" aria-modal="true">
                    <div className="fixed bg-gray-500 bg-opacity-75 transition-opacity"></div>

                    <div className="fixed inset-0 z-50">
                        <div className="flex min-h-screen items-center justify-center text-center">
                            <div className="relative w-full h-screen bg-white text-left shadow-xl transition-all">
                                <div className="h-full p-4">
                                    <History sessions={sessions} setSessions={setSessions} />
                                </div>

                                <div className="absolute -top-0 -right-0 p-4">
                                    <div onClick={() => setHistoryVisible(false)}>
                                        <VscChromeClose className="text-gray-500 text-2xl cursor-pointer hover:text-gray-800" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default App;
