import React, { useEffect, useState } from 'react';
import { FaTimes } from 'react-icons/fa';
import HighlightPHIWords from './../HighlightPHIWords';
import TranscriptionSettingsDisplay from './../TranscriptionSettingsDisplay';
import { formatSoapText } from './../Common/utils';
import './History.css';
import { EllipsisVerticalIcon } from '@heroicons/react/24/outline';
import { CiViewColumn } from 'react-icons/ci';

const History = ({ sessions, setSessions }) => {
    const [activeTabs, setActiveTabs] = useState({});
    const [menuOpen, setMenuOpen] = useState({});
    const [visibleSessions, setVisibleSessions] = useState(sessions);

    useEffect(() => {
        setVisibleSessions(sessions);
    }, [sessions]);

    useEffect(() => {
        const initialTabs = {};
        sessions.forEach((session) => {
            initialTabs[session.id] = 'transcript';
        });
        setActiveTabs(initialTabs);
    }, [sessions]);

    const toggleMenu = (sessionId) => {
        setMenuOpen((prev) => ({ ...prev, [sessionId]: !prev[sessionId] }));
    };

    const handleSave = (sessionId) => {
        const session = sessions.find((s) => s.id === sessionId);
        if (!session) {
            console.error('Session not found');
            return;
        }

        // session data
        let dataToSave = `Transcription Settings:\n`;
        dataToSave += `API: ${session.transcriptionSettings.useTranscribeMedical === 'true' ? 'Amazon Transcribe Medical' : 'Amazon Transcribe'}\n`;
        dataToSave += `Specialty: ${session.transcriptionSettings.specialty}\n`;
        dataToSave += `Type: ${session.transcriptionSettings.type}\n`;
        dataToSave += `PHI Detection: ${session.transcriptionSettings.enablePHIDetection}\n`;
        dataToSave += `Identify Speakers: ${session.transcriptionSettings.identifySpeakers}\n`;
        dataToSave += `Speaker Labels: ${session.transcriptionSettings.showSpeakerLabels}\n\n`;

        dataToSave += `Transcript:\n${session.liveTranscriptionText}\n\n`;
        dataToSave += `SOAP Note:\n${session.medicalNote ? session.medicalNote.text : 'SOAP note not available'}\n`;
        dataToSave += `H&P Note:\n${session.progressNote ? session.progressNote.text : 'H&P note not available'}\n`;

        // trigger file download
        const blob = new Blob([dataToSave], { type: 'text/plain;charset=utf-8' });
        const url = URL.createObjectURL(blob);

        const a = document.createElement('a');
        a.href = url;
        a.download = `session-${sessionId}.txt`; // filename
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
    };

    const handleHide = (sessionId) => {
        setVisibleSessions((prevSessions) => prevSessions.filter((session) => session.id !== sessionId));
    };

    const switchTab = (sessionId, tabName) => {
        setActiveTabs({ ...activeTabs, [sessionId]: tabName });
    };

    return (
        <div>
            <div className="flex overflow-x-auto h-full">
                {visibleSessions.map((session, index) => (
                    <div key={session.id} className={`flex-none w-96 h-full relative ${index !== 0 ? 'border-l border-gray-300' : ''}`}>
                        <div className="absolute top-0 right-3 p-1 flex items-center">
                            <EllipsisVerticalIcon className="h-5 w-5 cursor-pointer" onClick={() => toggleMenu(session.id)} />

                            {menuOpen[session.id] && (
                                <div className="absolute right-3 mt-28 w-32 bg-white rounded-md shadow py-1">
                                    <button className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left" onClick={() => handleSave(session.id)}>
                                        Save
                                    </button>
                                    <button className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left" onClick={() => handleHide(session.id)}>
                                        Hide
                                    </button>
                                </div>
                            )}
                        </div>

                        <div className="pb-4 px-4 overflow-y-auto h-full">
                            <div className="text-sm text-gray-600 border-b pb-3">
                                <TranscriptionSettingsDisplay settings={session.transcriptionSettings} />
                            </div>

                            <div className="flex mt-4">
                                <span className="inline-flex rounded-lg border border-gray-100 bg-gray-100 p-1">
                                    <button className={`inline-block rounded-md px-4 py-2 text-sm text-gray-500 hover:text-gray-700 focus:relative ${activeTabs[session.id] === 'transcript' ? 'bg-white ' : ''}`} onClick={() => switchTab(session.id, 'transcript')}>
                                        Transcript
                                    </button>
                                    <button className={`inline-block rounded-md px-4 py-2 text-sm text-gray-500 hover:text-gray-700 focus:relative ${activeTabs[session.id] === 'medical' ? 'bg-white ' : ''}`} onClick={() => switchTab(session.id, 'medical')}>
                                        SOAP Note
                                    </button>
                                    <button className={`inline-block rounded-md px-4 py-2 text-sm text-gray-500 hover:text-gray-700 focus:relative ${activeTabs[session.id] === 'progress' ? 'bg-white ' : ''}`} onClick={() => switchTab(session.id, 'progress')}>
                                        H&P Note
                                    </button>
                                </span>
                            </div>
                            <div className="overflow-y-auto text-base mt-7">
                                {activeTabs[session.id] === 'transcript' ? (
                                    <div>
                                        <HighlightPHIWords text={session.liveTranscriptionText} phiWords={session.phiWords || []} />
                                    </div>
                                ) : activeTabs[session.id] === 'medical' ? (
                                    <div>{session.medicalNote ? formatSoapText(session.medicalNote.text) : 'SOAP note not available'}</div>
                                ) : activeTabs[session.id] === 'progress' ? (
                                    <div>{session.progressNote ? formatSoapText(session.progressNote.text) : 'H&P note not available'}</div>
                                ) : null}
                            </div>
                        </div>
                    </div>
                ))}
            </div>

            {(!visibleSessions || visibleSessions.length === 0) && (
                <div className="h-screen flex flex-col items-center justify-center text-gray-500">
                    <div>
                        <CiViewColumn className="text-7xl text-gray-300" />
                    </div>
                    <div className="max-w-sm text-center leading-tight mt-5">Once you start recording, transcripts will appear here for your review.</div>
                </div>
            )}
        </div>
    );
};

export default History;
